<template>
    <div class="aboutMe">
        <h3>MY NAME IS</h3>
        <h1> Piotr Dąbrowski</h1>
        <p>and I am a fourth semester student at Bialystok University of Technology. While performing duties related to my university, I can easily develop my technical and soft skills.</p>
        <p>In my spare time, I try to gain knowledge in the field of computer science by gaining additional certificates that prove my understanding of a particular subject and by doing practical projects.</p>
    </div>
</template>
  

  <style>
   .aboutMe{
    text-align: center;
    font-family: 'Raleway', sans-serif;
   }
   
   .aboutMe h3{
    opacity: 0.4;
    margin: 0;
    padding: 0;
    padding-top: 20vh;
    font-size: 1.2vw;
    font-weight: 100;
   }

   .aboutMe h1{
    opacity: 0.8;
    color: white;
    margin: 0;
    font-size: 3.4vw;/*4.1rem*/
    padding-top: 2.8rem;
    letter-spacing: 0.3rem;
    margin-bottom: 8vh;
   }

   .aboutMe p{
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    font-size: 1.4vw;/*1.6rem*/
    width: 50vw;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1.5vh;
    font-weight: 100;
    min-width: 50vw;
   }
   

  </style>
  